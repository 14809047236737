/* eslint-disable import/namespace */
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

import { Sentry } from "~/lib/sentry";

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: "https://ce36147e377545c45a9a4dccdc85e1fd@o4508104843460608.ingest.us.sentry.io/4508133184503808",
  tracesSampleRate: 0.5,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: true,
    }),
  ],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
